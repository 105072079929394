import Archive from "~/components/blog/archive.js"
import Layout from "~/components/layout/layout"
import React from "react"
import SEO from "~/components/seo.js"
import { graphql } from "gatsby"

// Blog All Categories Page
const BlogPage = ({ location, pageContext, data }) => {
  const seo = data.wpPage.seo;
  return (
    <Layout location={location}>
      <SEO meta={seo} />
      <Archive pageContext={pageContext} data={data} location={location} />
    </Layout>
  )
}

export const query = graphql`
  query Posts($offset: Int!, $perPage: Int!) {
    allWpPost(
      limit: $perPage
      skip: $offset
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        title
        uri
        slug
        date
        featuredImage {
          node {
            sourceUrl
            srcSet
            sizes
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
      }
      pageInfo {
        currentPage
        pageCount
      }
    }
    allWpCategory(
      filter: {count: {gt: 0}}
    ) {
      nodes {
        name
        slug
        uri
      }
    },
    wpPage(slug: {eq: "blog"}) {
      seo {
        title
        metaDesc
        opengraphDescription
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphImage {
          localFile {
            url
          }
        }
        opengraphPublisher
        opengraphPublishedTime
        opengraphModifiedTime
      }
    }
  }
`

export default BlogPage
